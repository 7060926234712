@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url('./fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: normal;
}

* {
  font-family: 'Satoshi';
  font-weight: normal;
}

body,
html {
  height: 100%;
  /* The image used */
  background-image: url('./data/corona_ft.png');

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Action-button-group {
  position: 'absolute';
  bottom: 70px;
  right: 50%;
}

.App-body {
  background-position: 'center';
  background-size: 'cover';
  background-repeat: 'no-repeat';
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.Card {
  margin: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .InventoryPanel {
    border: '1px solid white';
    background-color: 'darkgray';
    border-radius: '3px';
    color: 'white';
    font-size: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
    margin: 5px;
  }
}

@media (min-width: 481px) {
  .InventoryPanel {
    border: '1px solid white';
    background-color: 'darkgray';
    border-radius: '3px';
    color: 'white';
    font-size: 14px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
    margin: 5px;
  }
}

@media (min-width: 900px) {
  .InventoryPanel {
    border: '1px solid white';
    background-color: 'darkgray';
    border-radius: '3px';
    color: 'white';
    font-size: 18px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
    margin: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .AboutTab {
    max-width: 600px;
    max-height: 450px;
    padding: 5px;
    text-align: 'center';
  }
}

.BMRow {
  color: 'white';
  margin: 'auto';
  margin-bottom: '25px';
  background-color: 'rgba(14,14,14,0)';
  text-align: 'center';
  align-items: 'center';
}

.AboutTab {
  max-width: 800px;
  max-height: 450px;
  padding: 5px;
  text-align: 'center';
}

.InventoryPanel table {
  margin: 5px auto;
}

.InventoryPanel tr td {
  background-color: gray;
  border: 2px solid darkgray;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  text-align: center;
}

.InventoryPanelTitle {
  margin-top: 10px;
  width: 100%;
  align-items: center;
  text-align: center;
}

#UserNFTPanel::-webkit-scrollbar {
  display: none;
}
#UserNFTPanel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#UserSelectedNFTPanel::-webkit-scrollbar {
  display: none;
}
#UserSelectedNFTPanel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.OathDataPanel {
  margin: auto auto auto auto;
  padding: 5px;
  text-align: center;
  display: 'table';
  position: 'absolute';
  bottom: 30;
  left: 50%;
  transform: translate(-50%, -50%);
}

#ClaimButton {
  width: 150;
  margin: 5px auto;
  position: 'absolute';
  bottom: 30;
  left: 50%;
  transform: translate(-50%, -50%);
}

.OathDataPanel tr td {
  background-color: darkgray;
  border: none;
  text-align: right;
  width: auto;
  height: auto;
  padding: 3px;
}

.textAlignRight {
  text-align: right;
}

#DisplayPicture {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: 'transparent';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: 'center';
}
#DisplayPicture img{
  max-width: '100%';
  width: 'auto';
  height: '100%';
}
.fire {
  animation: burn 1.5s linear infinite alternate;
}

@keyframes burn {
  from {
    box-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.4em #ffae34, 0.2em -0.3em 0.3em #ec760c,
      -0.2em -0.4em 0.4em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.7em #451b0e;
  }
  45% {
    box-shadow: 0.1em -0.2em 0.5em #fefcc9, 0.15em 0 0.4em #feec85,
      -0.1em -0.25em 0.5em #ffae34, 0.15em -0.45em 0.5em #ec760c,
      -0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      0.2em -1em 0.8em #451b0e;
  }
  70% {
    box-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.6em #ffae34, 0.2em -0.3em 0.4em #ec760c,
      -0.2em -0.4em 0.7em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.9em #451b0e;
  }
  to {
    box-shadow: -0.1em -0.2em 0.6em #fefcc9, -0.15em 0 0.6em #feec85,
      0.1em -0.25em 0.6em #ffae34, -0.15em -0.45em 0.5em #ec760c,
      0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      -0.2em -1em 0.8em #451b0e;
  }
}

.fireText {
  animation: burnText 1.5s linear infinite alternate;
}

@keyframes burnText {
  from {
    text-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.4em #ffae34, 0.2em -0.3em 0.3em #ec760c,
      -0.2em -0.4em 0.4em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.7em #451b0e;
  }
  45% {
    text-shadow: 0.1em -0.2em 0.5em #fefcc9, 0.15em 0 0.4em #feec85,
      -0.1em -0.25em 0.5em #ffae34, 0.15em -0.45em 0.5em #ec760c,
      -0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      0.2em -1em 0.8em #451b0e;
  }
  70% {
    text-shadow: -0.1em 0 0.3em #fefcc9, 0.1em -0.1em 0.3em #feec85,
      -0.2em -0.2em 0.6em #ffae34, 0.2em -0.3em 0.4em #ec760c,
      -0.2em -0.4em 0.7em #cd4606, 0.1em -0.5em 0.7em #973716,
      0.1em -0.7em 0.9em #451b0e;
  }
  to {
    text-shadow: -0.1em -0.2em 0.6em #fefcc9, -0.15em 0 0.6em #feec85,
      0.1em -0.25em 0.6em #ffae34, -0.15em -0.45em 0.5em #ec760c,
      0.1em -0.5em 0.6em #cd4606, 0 -0.8em 0.6em #973716,
      -0.2em -1em 0.8em #451b0e;
  }
}

.BtnConnect {
  height: 10;
  cursor: 'pointer';
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(00%, 0px);
  }
  50% {
    transform: translate(0%, 15px);
  }
  100% {
    transform: translate(0%, -0px);
  }
}

.RangeSelector {
  margin: 10px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  width: 60px;
  cursor: pointer;
}
.RangeSelector:active {
  border: 1px solid purple;
}
.RangeSelector:hover {
  background-color: purple;
  border: 1px solid white;
}
